import React from 'react';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { ImgLeft, inner, outer, SiteArchiveHeader, SiteHeader, SiteMain, SiteNavMain, TextJustify } from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import config from '../website-config';

interface AuthorTemplateProps {
  location: Location;
  data: {
    strapiTeacher: {
      seo: {
        excerpt: string
      }
      name: string
      bio: string
      picture: {
        localFile:  {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
            original: {
              src: string
              height: number
              width: number
            }
          }
        }
      }
    }
  };
}

const Teacher = ({ data, location }: AuthorTemplateProps) => {
  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>{data.strapiTeacher.name} - {config.title}</title>
        <meta name="description" content={data.strapiTeacher.seo.excerpt} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:title" content={`${data.strapiTeacher.name} - ${config.title}`} />
        <meta property="og:description" content={data.strapiTeacher.seo.excerpt} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        <meta property="og:image" content={`${config.siteUrl}${data.strapiTeacher.picture.localFile.childImageSharp.original.src}`}/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${data.strapiTeacher.name} - ${config.title}`} />
        <meta name="twitter:description" content={data.strapiTeacher.seo.excerpt} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        <meta name="twitter:image" content={`${config.siteUrl}${data.strapiTeacher.picture.localFile.childImageSharp.original.src}`}/>
        {data.strapiTeacher.picture.localFile.childImageSharp.original.width && <meta property="og:image:width" content={data.strapiTeacher.picture.localFile.childImageSharp.original.width.toString()} />}
        {data.strapiTeacher.picture.localFile.childImageSharp.original.height && <meta property="og:image:height" content={data.strapiTeacher.picture.localFile.childImageSharp.original.height.toString()} />}
      </Helmet>
      <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">{data.strapiTeacher.name}</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                {/* <h5>
                  A starter template for Gatsby <br /> GitHub: <a href="https://github.com/scttcper/gatsby-casper">scttcper/gatsby-casper</a>
                </h5> */}
                <GatsbyImage image={getImage(data.strapiTeacher.picture.localFile.childImageSharp.gatsbyImageData)!} css={ImgLeft} alt={data.strapiTeacher.name}/>
                <ReactMarkdown css={TextJustify}>{data.strapiTeacher.bio}</ReactMarkdown>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query($teacher: String) {
    strapiTeacher(id: {eq: $teacher}) {
      seo {
        excerpt
      }
      name
      bio
      picture {
        localFile {
          childImageSharp {
            gatsbyImageData
            original {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: ${colors.bodyBackground} //#fff;
  }

  // @media (prefers-color-scheme: dark) {
  //   .site-main {
  //     /* background: var(--darkmode); */
  //     background: ${colors.darkmode};
  //   }
  // }
`;

export default Teacher;
